import React, { useEffect, useState } from "react";
// import Footer from './Footer';
import Goatlogo from "../asset/images/BANI.png";
// import Bani from "../asset/images/BANI.png"
import { ImTelegram } from "react-icons/im";
import { makeApiRequest } from "../services/commonAxiosService";
import { toast } from "react-toastify";
import { BsCheckLg } from "react-icons/bs";
import { useNavigate } from "react-router-dom";


function Thirdtab({ data }) {
  const navigate = useNavigate();
  let chatId;
  if (data) {
    chatId = data;
  } else {
    chatId = localStorage.getItem("userId");
  }
  const [taskData, setTaskData] = useState([]);
  useEffect(() => {
    tabBasedTasks("Bani");
  }, []);

  const tabBasedTasks = async (Tasktype) => {
    try {
      let tasktype = "Bani";
      if (Tasktype === "Partners") {
        tasktype = "Partners";
      } else {
        tasktype = "Bani";
      }
      console.log("tasktype", tasktype);
      let paramsData = {
        userId: chatId,
        taskType: tasktype,
      };
      let params = {
        url: "view-tasks",
        method: "POST",
        data: paramsData,
      };
      let response = await makeApiRequest(params);
      console.log("response", response);

      if (response.status) {
        setTaskData(response.data);
      }
    } catch (error) {
      console.log("tabBasedTasks error", error);
    }
  };
  const doTaskSubmission = async (taskId,taskType,taskUrl) => {    
    try {
      window.location.href = taskUrl
      let paramsData = {
        userId: chatId,
        taksId: taskId,
      };
      let payLoad = {
        url: "do-task",
        method: "POST",
        data: paramsData,
      };
      let taskRes = await makeApiRequest(payLoad);
      if (taskRes.status) {
        toast.success(taskRes.message, { theme: "dark" });
        tabBasedTasks(taskType);
      }
    } catch (error) {
      console.log("doTaskSubmission error", error);
    }
  };

  return (
    <div className="goat-secondtabs-01">
      <div className="container">
        <div className="row min-vh-100">
          <div className="col-lg-12">
            <div className="">
              <h2
                className="text-white py-4 text-center"
                style={{ fontSize: "45px" }}
              >
                Missions
              </h2>
              <div className="goat-ranking-whl">
                <div>
                  <ul
                    class="nav nav-pills mb-3 d-flex justify-content-around"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li class="nav-item" role="presentation">
                      <button
                        onClick={() => tabBasedTasks("Bani")}
                        class="nav-link active text-muted fw-bold goat-total-new-heading"
                        id="pills-home-goats-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home-goats"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        $BANI
                      </button>
                    </li>
                    <li class="nav-item" role="presentation">
                      <button
                        onClick={() => tabBasedTasks("Partners")}
                        class="nav-link text-muted fw-bold goat-total-new-heading"
                        id="pills-profile-partners-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-profile-partners"
                        type="button"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        PARTNERS
                      </button>
                    </li>
                  </ul>
                  <div class="tab-content" id="pills-tabContent">
                    <div
                      class="tab-pane fade show active"
                      id="pills-home-goats"
                      role="tabpanel"
                      aria-labelledby="pills-home-goats-tab"
                    >
                      <div>
                        <p className="goat-special-mission fw-bold">$Bani</p>

                        <div className=" px-2 my-3 bg-dark goat-posts">
                          {taskData.length > 0 ? (
                            taskData.map((data) => {
                              return (
                                <>
                                  <div className="goat-react-posts py-4 d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center justify-content-between ">
                                      <div className="">
                                        <img
                                          alt=""
                                          className="alt-goat-tabs"
                                          src={data.taskImgUrl}
                                        />
                                        {/* <p className="text-white goat-news-22 mb-auto">
                                          <a href="/">
                                            <img
                                              class="goat-cross-over-02"
                                              src="https://dev.goatsbot.xyz/icons/x.png?w=3840&amp;q=75"
                                            />
                                          </a>
                                        </p> */}
                                      </div>
                                      <div>
                                        <p className="mb-auto">
                                          <span className="text-white mb-5 goats-earn-01 fw-bold">
                                            {data.taskTitle}
                                          </span>
                                          <br />
                                          <span className="text-muted fw-bold total-small-size-ctn">
                                            +{data.taskReward} $BANI
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                    <div>
                                      {data.userTaskData.length ? (
                                        <>
                                          <span className="goat-check-01">
                                            <BsCheckLg
                                              className="text-white"
                                              style={{ fontSize: "20px" }}
                                            />
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          <button
                                            onClick={() =>
                                              doTaskSubmission(
                                                data._id,
                                                data.taskType,
                                                data.taskDesc
                                              )
                                            }
                                            className="goats-do-btn"
                                          >
                                            DO
                                          </button>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </>
                              );
                            })
                          ) : (
                            <>
                              <h2>There is no tasks</h2>
                            </>
                          )}
                        </div>

                        <div className="my-4">
                        </div>
                      </div>
                    </div>
                    <div
                      class="tab-pane fade"
                      id="pills-profile-partners"
                      role="tabpanel"
                      aria-labelledby="pills-profile-partners-tab"
                    >
                      <div>
                        <div>
                          <p className="goat-wcoin-head fw-bold">Partners</p>
                          <div className=" px-2 my-3 bg-dark goat-posts">
                          {taskData.length > 0 ? (
                            taskData.map((data) => {
                              return (
                                <>
                                  <div className="goat-react-posts py-4 d-flex align-items-center justify-content-between">
                                    <div className="d-flex align-items-center justify-content-between ">
                                      <div className="">
                                        <img
                                          alt=""
                                          className="alt-goat-tabs"
                                          src={data.taskImgUrl}
                                        />
                                        {/* <p className="text-white goat-news-22 mb-auto">
                                          <a href="/">
                                            <img
                                              class="goat-cross-over-02"
                                              src="https://dev.goatsbot.xyz/icons/x.png?w=3840&amp;q=75"
                                            />
                                          </a>
                                        </p> */}
                                      </div>
                                      <div>
                                        <p className="mb-auto">
                                          <span className="text-white mb-5 goats-earn-01 fw-bold">
                                            {data.taskTitle}
                                          </span>
                                          <br />
                                          <span className="text-muted fw-bold total-small-size-ctn">
                                            +{data.taskReward} $BANI
                                          </span>
                                        </p>
                                      </div>
                                    </div>
                                    <div>
                                      {data.userTaskData.length ? (
                                        <>
                                          <span className="goat-check-01">
                                            <BsCheckLg
                                              className="text-white"
                                              style={{ fontSize: "20px" }}
                                            />
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          <button
                                            onClick={() =>
                                              doTaskSubmission(
                                                data._id,
                                                data.taskType,
                                                data.taskDesc
                                              )
                                            }
                                            className="goats-do-btn"
                                          >
                                            DO
                                          </button>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </>
                              );
                            })
                          ) : (
                            <>
                              <h2>There is no tasks</h2>
                            </>
                          )}
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default Thirdtab;
