import React, { useState, useEffect } from "react";
import "../asset/css/fruitsline.css";
import { FaCaretSquareLeft } from "react-icons/fa";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { ImNotification } from "react-icons/im";
import { PiDotsThreeCircle } from "react-icons/pi";
import { Link } from "react-router-dom";
// import { MdCancel } from "react-icons/md";
import Goldentoken from "../asset/images/goldentoken.png";
import Bitcointoken from "../asset/images/Bitcointoken.png";
import BNBtokens from "../asset/images/BNBtokens.png";
import { MdCancel } from "react-icons/md";
import { makeApiRequest } from "../services/commonAxiosService";
const SlotMachine = () => {
  let userId =
    localStorage.getItem("userId") != undefined
      ? localStorage.getItem("userId")
      : "";

  const symbols = [
    "7", "🔔", "🥕", "🍒", "🍋", "🍇", "🍉", "🍊", "⭐", "🎰", "💎", "🍀", "🎲", "🥭", "🧁"
  ];
  const payouts = {
    "777": {
      1: 5,
      2: 10,
      3: 15,
      4: 20,
      
      5: 25,
      6: 30,
      7: 35,
      8: 40,
      9: 45,
      10: 50,
    },
    "🔔🔔🔔": {
      1: 10,
      2: 20,
      3: 30,
      4: 40,
      5: 50,
      6: 60,
      7: 70,
      8: 80,
      9: 90,
      10: 100,
    },
    "🥕🥕🥕": {
      1: 25,
      2: 50,
      3: 75,
      4: 100,
      5: 125,
      6: 150,
      7: 175,
      8: 200,
      9: 225,
      10: 250,
    },
  };


  

  const [betAmount, setBetAmount] = useState(2);
  const [coins, setCoins] = useState(
    () => parseInt(localStorage.getItem("coins")) || 20
  );
  const [slots, setSlots] = useState([
    "7", "🔔", "🥕"
  ]);
  const [result, setResult] = useState("");
  const [gameCount, setGameCount] = useState(false);

  useEffect(() => {
    localStorage.setItem("coins", coins);
    getGameCount();
  }, [coins]);

  let getGameCount = async () => {
    try {
      let payLoad = {
        gameType: 2,
        chatId: userId,
      };
      let data = {
        url: "get-game-count",
        method: "post",
        data: payLoad,
      };
      let response = await makeApiRequest(data);
      if (response.status) {
        setGameCount(true);
      }else{
        setGameCount(false); 
      }
    } catch (error) {
      console.log("getGameCount err", error);
    }
  };

  const playSlot = async () => {
    if (coins < betAmount) {
      setResult("Game Over! You ran out of coins.");
      return;
    }

    setCoins((prevCoins) => prevCoins - betAmount);
    const randomSymbols = [];

    // Random animation
    const randomInterval = setInterval(() => {
      setSlots([
        symbols[Math.floor(Math.random() * symbols.length)],
        symbols[Math.floor(Math.random() * symbols.length)],
        symbols[Math.floor(Math.random() * symbols.length)],
      ]);
    }, 100);

    setTimeout(async () => {
      clearInterval(randomInterval);

      for (let i = 0; i < 3; i++) {
        randomSymbols.push(symbols[Math.floor(Math.random() * symbols.length)]);
      }

      setSlots(randomSymbols);

      const combination = randomSymbols.join("");
      let gScore = 0;
      if (payouts[combination] == payouts["777"]) {
        gScore = 40;
        setResult(`You won ${gScore} coins!`);
      }
      if(payouts[combination] == payouts["🥕🥕🥕"]) {
        gScore = 20;
        setResult(`You won ${gScore} coins!`);
      }
      if(payouts[combination] == payouts["🔔🔔🔔"]){
        gScore = 10;
        setResult(`You won ${gScore} coins!`);
      }else{
        setResult("Sorry, no win this time.");
      }
      let payLoad = {
        chatId: userId,
        gameType: 2,
        gameScore: gScore,
      };
      let data = {
        url: "game-count",
        method: "post",
        data: payLoad,
      };

      let response = await makeApiRequest(data);
      console.log("api response", response);
      getGameCount();
      
    }, 2000);
  };

  const restartGame = () => {
    setCoins(20);
    setResult("");
    setSlots(["?", "?", "?"]);
  };

  return (
    <div className="slot-machine goat-full-box-02 min-vh-100 text-center  fruitscard-modals-table justify-content-center  align-items-center">
      <div className="container ">
        <div className="row ">
          <div className="col-12 px-0">
            <div>
              <div className="d-flex justify-content-between bani-close-items bg-dark align-items-center py-2">
                <span
                  className="text-primary ps-2"
                  style={{ fontSize: "20px" }}
                >
                  Close
                </span>
                <span
                  className="text-white text-center "
                  style={{ fontSize: "18px" }}
                >
                  <b>$BANI</b>
                  <br />
                  <span
                    className="mt-0 pt-0 text-muted"
                    style={{ fontSize: "16px" }}
                  >
                    mini app
                  </span>
                </span>
                <span className="text-primary pe-2">
                  <PiDotsThreeCircle
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    style={{ fontSize: "33px" }}
                  />
                </span>

                {/* <==============> */}
                <div
                  class="modal fade"
                  id="exampleModal"
                  tabindex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h1
                          class="modal-title fs-5 fw-bold text-center"
                          id="exampleModalLabel"
                        ></h1>
                        <p
                          className="mb-auto"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        >
                          <MdCancel
                            style={{ fontSize: "40px", color: "#b7b4b4" }}
                          />
                        </p>
                        {/* <button ></button> */}
                      </div>
                      <div class="modal-body pt-0">
                        <h5 className="text-center">How its Work ?</h5>
                        <p className="text-center">
                          When using the slot machine,it costs 1TON park ticket
                          per spin every time you strik a 3 streak comboo $TON
                          hit the big prize and 20 $TON will be sent to your
                          pocket
                        </p>
                        <div>
                          <table>
                            <thead
                              style={{ borderBottom: "1px solid #b5b1b1" }}
                            >
                              <tr>
                                <th>Symbol</th>
                                <th className="text-start">Prize</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <img
                                    className="Goldentoken-img"
                                    src={Goldentoken}
                                  />
                                </td>
                                <td className="fw-bold text-start">$TON</td>
                              </tr>
                              <tr>
                                <td>
                                  <img
                                    className="Goldentoken-img"
                                    src={Bitcointoken}
                                  />
                                </td>
                                <td className="fw-bold text-start">
                                  Lots of $TON
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <img
                                    className="Goldentoken-img"
                                    src={BNBtokens}
                                  />
                                </td>
                                <td className="fw-bold text-start">$BANI</td>
                              </tr>
                              <tr>
                                <td>
                                  <img
                                    className="Goldentoken-img"
                                    src={Goldentoken}
                                  />
                                </td>
                                <td className="fw-bold text-start">
                                  Lots of $BANI
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <img
                                    className="Goldentoken-img"
                                    src={Bitcointoken}
                                  />
                                </td>
                                <td className="fw-bold text-start">
                                  Spinning Coin/Free spin
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <img
                                    className="Goldentoken-img"
                                    src={BNBtokens}
                                  />
                                </td>
                                <td className="fw-bold text-start">
                                  Lottery Ticket
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {/* <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary">Save changes</button>
                      </div> */}
                    </div>
                  </div>
                </div>

                {/* <=================> */}
              </div>
              <div className="d-flex justify-content-between   align-items-center py-3">
                <span
                  className="text-primary ps-2"
                  style={{ fontSize: "20px" }}
                >
                  <Link to="/">
                    <AiOutlineArrowLeft className="text-white fs-4" />
                  </Link>
                </span>
                <span
                  className="text-white text-center "
                  style={{ fontSize: "18px" }}
                >
                  Daily-check-in
                </span>
                <span className="text-primary pe-2">
                  {" "}
                  <ImNotification className="text-white fs-4" />
                </span>
              </div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column">
        <h1 className="text-white">Slot Machine s</h1>
        <div className="slots">
          {slots.map((slot, index) => (
            <div key={index} className="slot">
              {slot}
            </div>
          ))}
        </div>
        {/* <label htmlFor="bet" className='text-white'>Bet Amount:</label>
      <input
        type="number"
        id="bet"
        min="1"
        max="10"
        value={betAmount}
        onChange={(e) => setBetAmount(parseInt(e.target.value))}
      /> */}
        {/* <Link to="/">
      <FaCaretSquareLeft  className='fruitcard-left-icon' style={{color:"#ff6600"}}/>
      </Link> */}
        {gameCount == true ? (
          <>
            <div>
              <button className="me-2" onClick={playSlot}>
                Play
              </button>
              {/* <button onClick={restartGame}>Restart</button> */}
            </div>
          </>
        ) : (
          <>
            <h2>You have reached max Limit</h2>
          </>
        )}
        <div id="result" className="text-white">
          {result}
        </div>
      </div>
    </div>
  );
};

export default SlotMachine;
