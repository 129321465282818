let config = {}
if(process.env.NODE_ENV == "development"){
    console.log("development");
    config = {
        backendurl : "https://api.banicoin.io/user/"
    }
}else{
    console.log("live");
    config = {
        backendurl : "https://api.banicoin.io/user/"
    }
}

module.exports = config