import React, { useState, useEffect, useRef } from 'react';
import '../asset/css/Style.css';
import { BiSolidDownArrowSquare } from "react-icons/bi";
import { BiSolidUpArrowSquare } from "react-icons/bi";
import { BiSolidLeftArrowSquare } from "react-icons/bi";
import { BiSolidRightArrowSquare } from "react-icons/bi";
import { FaCaretSquareLeft } from "react-icons/fa";
import { Link } from 'react-router-dom';
const gridSize = 20; // Size of each grid in the snake game

const SnakeGame = () => {
  const canvasRef = useRef(null); // Reference to the canvas element
  const [canvasSize, setCanvasSize] = useState(400); // Default canvas size
  const [snake, setSnake] = useState([{ x: 200, y: 200 }]); // Snake starting position
  const [food, setFood] = useState({ x: 100, y: 100 }); // Food starting position
  const [direction, setDirection] = useState({ x: 0, y: 0 }); // Initial direction
  const [snakeLength, setSnakeLength] = useState(1); // Snake initial length
  const [score, setScore] = useState(0); // Initial score
  const [gameOver, setGameOver] = useState(false); // Game over flag

  // Place food randomly on the grid
  const placeFood = () => {
    setFood({
      x: Math.floor(Math.random() * (canvasSize / gridSize)) * gridSize,
      y: Math.floor(Math.random() * (canvasSize / gridSize)) * gridSize,
    });
  };

  useEffect(() => {
    // Set initial food position when component mounts
    placeFood();
  }, [canvasSize]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case 'ArrowUp':
          if (direction.y === 0) setDirection({ x: 0, y: -1 });
          break;
        case 'ArrowDown':
          if (direction.y === 0) setDirection({ x: 0, y: 1 });
          break;
        case 'ArrowLeft':
          if (direction.x === 0) setDirection({ x: -1, y: 0 });
          break;
        case 'ArrowRight':
          if (direction.x === 0) setDirection({ x: 1, y: 0 });
          break;
        default:
          break;
      }
    };

    // Attach keyboard event listener for snake control
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [direction]);

  // Game loop
  useEffect(() => {
    const gameLoop = setInterval(() => {
      if (gameOver) return; // Stop the game if it's over

      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas before each frame

      // Move snake
      const newSnake = [...snake];
      const head = { x: newSnake[0].x + direction.x * gridSize, y: newSnake[0].y + direction.y * gridSize };
      newSnake.unshift(head); // Add new head to the snake

      // Check if snake eats the food
      if (head.x === food.x && head.y === food.y) {
        setSnakeLength(snakeLength + 1); // Increase snake length
        setScore(score + 1); // Increase score
        placeFood(); // Reposition food
      }

      // Limit snake length
      if (newSnake.length > snakeLength) {
        newSnake.pop(); // Remove the last segment if it's longer than snake length
      }

      setSnake(newSnake); // Update snake state

      // Draw food
      drawRect(ctx, food.x, food.y, '#ff00ff');

      // Draw snake
      newSnake.forEach((segment) => drawRect(ctx, segment.x, segment.y, '#00ffcc'));

      // Check for collisions
      if (checkCollision(newSnake)) {
        setGameOver(true); // Trigger game over if there's a collision
      }
    }, 100);

    return () => clearInterval(gameLoop); // Clean up interval on unmount
  }, [snake, direction, gameOver, food, snakeLength]);

  // Helper function to draw rectangles (snake and food)
  const drawRect = (ctx, x, y, color) => {
    ctx.fillStyle = color;
    ctx.shadowBlur = 15;
    ctx.shadowColor = color;
    ctx.fillRect(x, y, gridSize, gridSize);
  };

  // Check for collisions with walls or self
  const checkCollision = (snake) => {
    const head = snake[0];
    // Check wall collision
    if (head.x < 0 || head.x >= canvasSize || head.y < 0 || head.y >= canvasSize) {
      return true;
    }
    // Check self collision
    for (let i = 1; i < snake.length; i++) {
      if (head.x === snake[i].x && head.y === snake[i].y) {
        return true;
      }
    }
    return false;
  };

  // Reset game state
  const restartGame = () => {
    setSnake([{ x: canvasSize / 2, y: canvasSize / 2 }]);
    setDirection({ x: 0, y: 0 });
    setSnakeLength(1);
    setScore(0);
    setGameOver(false);
    placeFood(); // Reset food position
  };

  // Handle directional button clicks
  const handleDirection = (newDirection) => {
    setDirection(newDirection);
  };

  return (
    <div className="game-container">
      {/* <h1>Snake Neon Retro</h1> */}
      <div id="score">Score: {score}</div>
      <canvas
        id="gameCanvas"
        ref={canvasRef}
        width={canvasSize}
        height={canvasSize}
      ></canvas>
      {gameOver && <div className="game-over">Game Over</div>}
      {gameOver && <button id="restartButton" onClick={restartGame}>Try Again</button>}

      {/* Direction buttons */}
      <div className="direction-buttons">
        <button className='square-lighting-btns-01 mt-0 d-flex justify-content-center align-items-center' style={{borderRadius:"50%"}} onClick={() => handleDirection({ x: 0, y: -1 })}><BiSolidUpArrowSquare style={{color:" #00ffcc",boxShadow:"rgb(0, 255, 204) 0px 1px 7px",borderRadius:"8px"}}/></button>
        <button className='square-lighting-btns-01 mt-0 d-flex justify-content-center align-items-center' style={{borderRadius:"50%"}} onClick={() => handleDirection({ x: -1, y: 0 })}><BiSolidLeftArrowSquare style={{color:" #00ffcc",boxShadow:"rgb(0, 255, 204) 0px 1px 7px",borderRadius:"8px"}}/></button>
        <button className='square-lighting-btns-01 mt-0 d-flex justify-content-center align-items-center' style={{borderRadius:"50%"}} onClick={() => handleDirection({ x: 1, y: 0 })}><BiSolidRightArrowSquare style={{color:" #00ffcc",boxShadow:"rgb(0, 255, 204) 0px 1px 7px",borderRadius:"8px"}}/></button>
        <button className='square-lighting-btns-01 mt-0 d-flex justify-content-center align-items-center' style={{borderRadius:"50%"}} onClick={() => handleDirection({ x: 0, y: 1 })}><BiSolidDownArrowSquare style={{color:" #00ffcc",boxShadow:"rgb(0, 255, 204) 0px 1px 7px",borderRadius:"8px"}}/></button>
      </div>
      <Link to="/" className='snake-home-arrow'><FaCaretSquareLeft className='snake-home-arrow-01' style={{color:"rgb(0, 255, 204)"}}/>
</Link>
    </div>
  );
};

export default SnakeGame;
