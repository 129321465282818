import React, { useState, useEffect } from 'react'
import '../App.css';
import { AiOutlineArrowLeft } from "react-icons/ai";
import { ImNotification } from "react-icons/im";
import Loa from "../asset/images/loa.gif";
import Tickets from "../asset/images/tickets.webp"
import { Link } from 'react-router-dom';
import Lightlottery from "../asset/images/lightlottery.gif";
import Confetti from 'react-confetti'
import Pin from "../asset/images/BANI.png";
import { PiDotsThreeCircle } from "react-icons/pi";
import { MdCancel } from "react-icons/md";
import Goldentoken from "../asset/images/goldentoken.png";
import Bitcointoken from "../asset/images/Bitcointoken.png";
import BNBtokens from "../asset/images/BNBtokens.png"
// import Bani from "../asset/images/BANI.png"

function Jackpotpage() {
  const [activeDay, setActiveDay] = useState(1);

  const handleClick = (day) => {
    setActiveDay(day);
  };
  return (

    // <div className='whl-jackpot min-vh-100 position-relative goat-full-home-01'>
    //   <div className='container'>

    //     <div className='row px-0'>
    //       <div className='col-12'>
    //         <div className='d-flex justify-content-between align-items-center py-2'>
    //           <Link to="/">
    //           <AiOutlineArrowLeft className='text-white fs-4' />
    //           </Link>
    //           <p className='mb-auto  text-white'>Lucky lottery</p>
    //           <ImNotification className='text-white fs-4' />
    //         </div>
    //       </div>
    //     </div>

    //     <div className='row py-3'>
    //       <div className='col-lg-12'>
    //         <div className='position-relative'>
    //           <img src={Lightlottery} className='goat-light-lottery' />
    //           <div className='lottery-tokens-01 d-flex align-items-center justify-content-between'>
    //             <span className='alter-jackpot fw-bold text-muted'>JACKPOT</span>
    //             <span className='fw-bold alter-points-tokens'>41.01 $TON</span>
    //           </div>
    //         </div>
    //       </div>
    //     </div>

    //     <div className='row px-0'>
    //       <div className='my-2 bg-primary'>
    //         <p className='py-3'>jackpot</p>
    //       </div>

    //     </div>

    //     <div className='total-jackpot-box'>
    //       <div className='row mt-4 whole-jackpot-box new-lucky-lottery px-0'>
    //         <div className='col-lg-12 mx-auto'>
    //           <div className='bg-dark last-one-ctn mt-4 d-flex justify-content-between align-items-center p-3'>
    //             <div className='jackpot-rounded-top'>
    //               <p className='mb-auto'>
    //                 <input type="text" className='jackpot-first-box jackpot-input-boxex' placeholder='' />
    //               </p>
    //               <p className='   mb-auto'>
    //                 <input type="text" className='jackpot-second-box first py-2 mt-2' placeholder='' />
    //               </p>
    //             </div>
    //             <div className='jackpot-rounded'>

    //               <p className='mb-auto mt-4 first'>
    //                 <input type="text" className='jackpot-first-box-01 jackpot-input-boxex ' placeholder='' />
    //               </p>

    //               <p className=''>
    //                 <input type="text" className='jackpot-first-box-01 py-2 jackpot-input-boxex mt-1' placeholder="" />
    //               </p>
    //             </div>

    //           </div>
    //         </div>
    //       </div>
    //       <div className='row mt-4 whole-jackpot-box new-lucky-lottery px-0'>
    //         <div className='col-lg-12 mx-auto'>
    //           <div className='bg-dark last-one-ctn mt-4 d-flex justify-content-between align-items-center p-3'>
    //             <div className='jackpot-rounded-top'>
    //               <p className='mb-auto'>
    //                 <input type="text" className='jackpot-first-box jackpot-input-boxex' placeholder='' />
    //               </p>
    //               <p className='mb-auto'>
    //                 <input type="text" className='jackpot-second-box first py-2 mt-2' placeholder='' />
    //               </p>
    //             </div>
    //             <div className='jackpot-rounded'>

    //               <p className='mb-auto mt-4 first'>
    //                 <input type="text" className='jackpot-first-box-01 jackpot-input-boxex ' placeholder='' />
    //               </p>

    //               <p className=''>
    //                 <input type="text" className='jackpot-first-box-01 py-2 jackpot-input-boxex mt-1' placeholder="" />
    //               </p>
    //             </div>

    //           </div>
    //         </div>
    //       </div>
    //       <div className='row mt-4 whole-jackpot-box new-lucky-lottery px-0'>
    //         <div className='col-lg-12 mx-auto'>
    //           <div className='bg-dark last-one-ctn mt-4 d-flex justify-content-between align-items-center p-3'>
    //             <div className='jackpot-rounded-top'>
    //               <p className='mb-auto'>
    //                 <input type="text" className='jackpot-first-box jackpot-input-boxex' placeholder='' />
    //               </p>
    //               <p className='   mb-auto'>
    //                 <input type="text" className='jackpot-second-box first py-2 mt-2' placeholder='' />
    //               </p>
    //             </div>
    //             <div className='jackpot-rounded'>

    //               <p className='mb-auto mt-4 first'>
    //                 <input type="text" className='jackpot-first-box-01 jackpot-input-boxex ' placeholder='' />
    //               </p>

    //               <p className=''>
    //                 <input type="text" className='jackpot-first-box-01 py-2 jackpot-input-boxex mt-1' placeholder="" />
    //               </p>
    //             </div>

    //           </div>
    //         </div>
    //       </div>

    //     </div>


    //     <div className='row jackpot-numbers'>
    //       <div className=' px-2 bg-dark py-3  mt-0 d-flex justify-content-between align-items-center previous-winner-boxex align-items-center mt-2'>
    //         <p type="button" class="mb-0" data-bs-toggle="modal" data-bs-target="#exampleModal">
    //           <img src={Loa} className='loa-gif-img mb-auto' data-bs-toggle="modal"  data-bs-target="#exampleModal"/><span className='fw-bold text-white'  data-bs-toggle="modal"  data-bs-target="#exampleModal">Previous winner</span>
    //         </p>

    //         <div class="modal fade " id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    //           <div class="modal-dialog modal-dialog-bottom text-center ">

    //             <div class="modal-content">
    //             <Confetti className='paper'/>
    //               <div class="modal-header">
    //                 <h1 class="modal-title fs-5" id="exampleModalLabel"></h1>
    //                 <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
    //               </div>
    //               <div class="modal-body">
    //                 <p className='jackpot-us-dollars text-white'>US</p>
    //                 <h4 className='jackpot-dollars-ctn fw-bold my-3 text-white'>Congratulation sajjad2292 has won the last Jackpot of total:</h4>
    //                 <h1 className='jackpot-dollars-pointind fs-5 my-3 text-white'>402.232 $TON</h1>
    //                 <p className='my-3 text-white'>Time till next jackpot:</p>
    //                 <div className='my-3'>
    //                   <button className='me-1 goats-timer-sec text-white'><span>11<br/>hours</span></button>
    //                   <button className='me-1 goats-timer-sec'><span>52<br/>mins</span></button>
    //                   <button className='goats-timer-sec'>
    //   <span className='goats-timing'>{seconds}<br />secs</span>
    // </button>
    //                 </div>
    //                 <button className='bg-primary w-100 py-3 text-white border-0 models-go-back-btn fw-bold'>Go back</button>
    //               </div>

    //             </div>
    //           </div>
    //         </div>

    //         <Link to="/previousnumber">
    //           <button className='get-more-buttons fw-bold'>Get more <img className='jackpot-tickets' src={Tickets} /></button>
    //         </Link>

    //       </div>


          

    //       <div className='col-lg-12 py-3'>

    //         <div className='text-center'>
    //           <p className='text-center lucky-lottery-01'>Lucky number</p>
    //           <ul className='d-flex justify-content-center align-items-center px-0'>
    //             <li className='jackpot-tokens d-flex justify-content-center align-items-center fw-bold me-1'>8</li>
    //             <li className='jackpot-tokens d-flex justify-content-center align-items-center fw-bold me-1'>4</li>
    //             <li className='jackpot-tokens d-flex justify-content-center align-items-center fw-bold  me-1'>3</li>
    //             <li className='jackpot-tokens d-flex justify-content-center align-items-center fw-bold me-1'>1</li>
    //             <li className='jackpot-tokens d-flex justify-content-center align-items-center fw-bold me-1'>9</li>
    //             <li className='jackpot-tokens d-flex justify-content-center align-items-center fw-bold'>10</li>
    //           </ul>
    //         </div>
    //       </div>
    //     </div>

    //   </div>

    //   <div className='get-tickets text-center py-2'>
    //   <p className='fw-bold text-white fw-400 lucky-lottery-01 '>you have no tickets !</p>
    //   <button className='px-4 py-2 bg-danger text-white fw-bold get-tickets-btn-01'>Get tickets <img className='jackpot-tickets' src={Tickets} /></button>
    //   </div>

    //   <div className='goats-get-ticket'>
    //     <p className='fw-bold text-white'>you have no tickets</p>
    //     <button className='new-token-tickets-01'>Get Ticket<img className='jackpot-tickets' src={Tickets} /></button>
    //   </div>


    // </div>
    <div className='min-vh-100 goat-full-box-02 friends-zone jackpot-new-modals-sec'>

      <div className='container jackpot-modal-container'>
        <div className='row'>
          <div className='col-12'>
            <div>
              <div className='d-flex justify-content-between bani-close-items bg-dark align-items-center py-2'>
                <span className='text-primary ps-2' style={{ fontSize: "20px" }}>Close</span>
                <span className='text-white text-center ' style={{ fontSize: "18px" }}><b>$BANI</b><br /><span className='mt-0 pt-0 text-muted' style={{ fontSize: "16px" }}>mini app</span></span>
                <span className='text-primary pe-2'><PiDotsThreeCircle data-bs-toggle="modal" data-bs-target="#exampleModal" style={{ fontSize: "33px" }} /></span>
              </div>
              <div className='d-flex justify-content-between   align-items-center py-3'>
                <span className='text-primary ps-2' style={{ fontSize: "20px" }}>
                  <Link to="/">
                    <AiOutlineArrowLeft className='text-white fs-4' />
                  </Link>
                </span>
                <span className='text-white text-center ' style={{ fontSize: "18px" }}>Daily-check-in</span>
                <span className='text-primary pe-2'> <ImNotification className='text-white fs-4'/></span>

                {/* <=================> */}
               
                {/* <button type="button" class="btn btn-primary">
                  Launch demo modal
                </button> */}

             
                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                  <div class="modal-dialog">
                    <div class="modal-content">
                      <div class="modal-header">
                        <h1 class="modal-title fs-5 fw-bold text-center" id="exampleModalLabel"></h1>
                      <p className="mb-auto" data-bs-dismiss="modal" aria-label="Close"><MdCancel style={{fontSize:"40px",color:"#b7b4b4"}}/></p>
                        {/* <button ></button> */}
                      </div>
                      <div class="modal-body pt-0">
                        <h5 className='text-center'>How its Work ?</h5>
                      <p className='text-center'>When using the slot machine,it costs 1TON park ticket per spin every time you strik a 3 streak comboo
                          $TON hit the big prize and 20 $TON will be sent to your pocket
                        </p>
                        <div>
                          <table>
                            <thead style={{borderBottom:"1px solid #b5b1b1"}}>
                              <tr>
                                <th>Symbol</th>
                                <th className='text-start'>Prize</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td><img  className="Goldentoken-img" src={Goldentoken}/></td>
                                <td className='fw-bold text-start'>$TON</td>
                              </tr>
                              <tr>
                              <td><img className="Goldentoken-img" src={Bitcointoken}/></td>
                                <td className='fw-bold text-start'>Lots of $TON</td>
                              </tr>
                              <tr>
                              <td><img className="Goldentoken-img" src={BNBtokens}/></td>
                                <td className='fw-bold text-start'>$BANI</td>
                              </tr>
                              <tr>
                              <td><img className="Goldentoken-img" src={Goldentoken}/></td>
                                <td className='fw-bold text-start'>Lots of $BANI</td>
                              </tr>
                              <tr>
                              <td><img className="Goldentoken-img" src={Bitcointoken}/></td>
                                <td className='fw-bold text-start'>Spinning Coin/Free spin</td>
                              </tr>
                              <tr>
                              <td><img className="Goldentoken-img" src={BNBtokens}/></td>
                                <td className='fw-bold text-start'>Lottery Ticket</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      {/* <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary">Save changes</button>
                      </div> */}
                    </div>
                  </div>
                </div>

                {/* <=====================> */}
              </div>
              <div>

              </div>
              <div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='container '>
        <div className=''>
          <div className='row'>
            <div className='col-6 pe-0'>
              <div className=''>
                <button
                  className={`w-100 border-0 fw-bold text-white top-first-left-btn ${activeDay === 1 ? 'active-btn' : ''}`}
                  onClick={() => handleClick(1)}
                >
                  Day 1
                </button>
                <div className={`py-3 text-center box-def-01 ${activeDay === 1 ? 'active-image' : ''}`}>
                  {/* <img src={Pin} style={{ width: '50px', height: '50px' }} alt="Pin" /> */}
                  <img src={Pin} style={{ width: '50px', height: 'auto' }} alt="Pin" />
                  <p className=' mb-auto mt-3 table-bin-points py-1'>+500 $BANI</p>
                </div>
              </div>
            </div>

            <div className='col-6 ps-0' style={{ borderLeft: '1px solid black' }}>
              <div className=''>
                <button
                  className={`w-100 border-0 fw-bold text-white top-first-right-btn ${activeDay === 2 ? 'active-btn' : ''}`}
                  onClick={() => handleClick(2)}
                >
                  Day 2
                </button>
                <div className={`text-center box-def-01 py-3 ${activeDay === 2 ? 'active-image' : ''}`}>
                <img src={Pin} style={{ width: '50px', height: 'auto' }} alt="Pin" />
                  <p className=' mb-auto mt-3 table-bin-points py-1'>+500 $BANI</p>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-6 pe-0'>
              <div className='bg-dark'>
                <button
                  className={`w-100 border-0 fw-bold text-white new-bin-box-btn ${activeDay === 3 ? 'active-btn' : ''}`}
                  onClick={() => handleClick(3)}
                >
                  Day 3
                </button>
                <div className={`text-center box-def-01 py-3 ${activeDay === 3 ? 'active-image' : ''}`}>
                <img src={Pin} style={{ width: '50px', height: 'auto' }} alt="Pin" />
                  <p className=' mb-auto mt-3 table-bin-points py-1'>+500 $BANI</p>
                </div>
              </div>
            </div>
            <div className='col-6 ps-0' style={{ borderLeft: "1px solid black" }}>
              <div className='bg-dark'>
                <button
                  className={`w-100 border-0 fw-bold text-white new-bin-box-btn ${activeDay === 4 ? 'active-btn' : ''}`}
                  onClick={() => handleClick(4)}
                >
                  Day 4
                </button>
                <div className={`text-center box-def-01 py-3 ${activeDay === 4 ? 'active-image' : ''}`}>
                <img src={Pin} style={{ width: '50px', height: 'auto' }} alt="Pin" />
                  <p className=' mb-auto mt-3 table-bin-points py-1'>+500 $BANI</p>
                </div>
              </div>
            </div>


          </div>
          <div className='row'>
            <div className='col-6 pe-0'>
              <div className='bg-dark'>
                <button
                  className={`w-100 border-0 fw-bold text-white new-bin-box-btn ${activeDay === 5 ? 'active-btn' : ''}`}
                  onClick={() => handleClick(5)}
                >
                  Day 5
                </button>
                <div className={`text-center box-def-01 py-3 ${activeDay === 5 ? 'active-image' : ''}`}>
                <img src={Pin} style={{ width: '50px', height: 'auto' }} alt="Pin" />
                  <p className=' mb-auto mt-3 table-bin-points py-1'>+500 $BANI</p>
                </div>
              </div>
            </div>
            <div className='col-6 ps-0' style={{ borderLeft: "1px solid black" }}>
              <div className='bg-dark'>
                <button
                  className={`w-100 border-0 fw-bold text-white new-bin-box-btn ${activeDay === 6 ? 'active-btn' : ''}`}
                  onClick={() => handleClick(6)}
                >
                  Day 6
                </button>
                <div className={`text-center box-def-01 py-3 ${activeDay === 6 ? 'active-image' : ''}`}>
                <img src={Pin} style={{ width: '50px', height: 'auto' }} alt="Pin" />
                  <p className=' mb-auto mt-3 table-bin-points py-1'>+500 $BANI</p>
                </div>
              </div>
            </div>


          </div>

          <div className='row'>
            <div className='col-12'>
              <div>
                <button
                  className={`w-100 border-0 fw-bold text-white new-bin-box-btn ${activeDay === 7 ? 'active-btn' : ''}`}
                  onClick={() => handleClick(7)}
                >
                  Day 7
                </button>
                <div className={`text-center box-def-01 py-3 ${activeDay === 7 ? 'active-image' : ''}`} style={{ borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
                <img src={Pin} style={{ width: '50px', height: 'auto' }} alt="Pin" />
                  <p className=' mb-auto mt-3 table-bin-points py-1'>+500 $BANI</p>
                </div>
                <div>
                </div>
              </div>
            </div>
          </div>
        </div>



      </div>



    </div>

  )
}

export default Jackpotpage
{/* <img src={Pin} style={{ width: "50px", height: "50px" }} /> */ }
// new-bin-box-btn